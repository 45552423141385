exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-area-de-atuacao-tsx": () => import("./../../../src/pages/area-de-atuacao.tsx" /* webpackChunkName: "component---src-pages-area-de-atuacao-tsx" */),
  "component---src-pages-atendimento-ouvidoria-tsx": () => import("./../../../src/pages/atendimento/ouvidoria.tsx" /* webpackChunkName: "component---src-pages-atendimento-ouvidoria-tsx" */),
  "component---src-pages-atendimento-tsx": () => import("./../../../src/pages/atendimento.tsx" /* webpackChunkName: "component---src-pages-atendimento-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nossa-estrutura-tsx": () => import("./../../../src/pages/nossa-estrutura.tsx" /* webpackChunkName: "component---src-pages-nossa-estrutura-tsx" */),
  "component---src-pages-perfil-de-carga-tsx": () => import("./../../../src/pages/perfil-de-carga.tsx" /* webpackChunkName: "component---src-pages-perfil-de-carga-tsx" */),
  "component---src-pages-politicas-de-privacidade-tsx": () => import("./../../../src/pages/politicas-de-privacidade.tsx" /* webpackChunkName: "component---src-pages-politicas-de-privacidade-tsx" */),
  "component---src-pages-previsao-de-entrega-tsx": () => import("./../../../src/pages/previsao-de-entrega.tsx" /* webpackChunkName: "component---src-pages-previsao-de-entrega-tsx" */),
  "component---src-pages-rastreamento-encomendas-tsx": () => import("./../../../src/pages/rastreamento/encomendas.tsx" /* webpackChunkName: "component---src-pages-rastreamento-encomendas-tsx" */),
  "component---src-pages-rastreamento-resultado-tsx": () => import("./../../../src/pages/rastreamento/resultado.tsx" /* webpackChunkName: "component---src-pages-rastreamento-resultado-tsx" */),
  "component---src-pages-rastreamento-tsx": () => import("./../../../src/pages/rastreamento.tsx" /* webpackChunkName: "component---src-pages-rastreamento-tsx" */),
  "component---src-pages-seguranca-e-tecnologia-tsx": () => import("./../../../src/pages/seguranca-e-tecnologia.tsx" /* webpackChunkName: "component---src-pages-seguranca-e-tecnologia-tsx" */),
  "component---src-pages-seja-generoso-tsx": () => import("./../../../src/pages/seja-generoso.tsx" /* webpackChunkName: "component---src-pages-seja-generoso-tsx" */),
  "component---src-pages-sobre-nos-tsx": () => import("./../../../src/pages/sobre-nos.tsx" /* webpackChunkName: "component---src-pages-sobre-nos-tsx" */),
  "component---src-pages-unidades-tsx": () => import("./../../../src/pages/unidades.tsx" /* webpackChunkName: "component---src-pages-unidades-tsx" */),
  "component---src-templates-post-index-tsx": () => import("./../../../src/templates/Post/index.tsx" /* webpackChunkName: "component---src-templates-post-index-tsx" */),
  "component---src-templates-unidade-index-tsx": () => import("./../../../src/templates/Unidade/index.tsx" /* webpackChunkName: "component---src-templates-unidade-index-tsx" */)
}

